function carouselNormalization() {
	var items = $(".carousel-normalize .carousel-item"), //grab all slides
		heights = [], //create empty array to store height values
		tallest; //create variable to make note of the tallest slide

	if (items.length) {
		function normalizeHeights() {
			items.each(function() {
				//add heights to array
				heights.push($(this).height());
			});
			tallest = Math.max.apply(null, heights); //cache largest value
			items.each(function() {
				$(this).css("min-height", tallest + 32 + "px");
			});
		}
		normalizeHeights();

		$(window).on("resize orientationchange", function() {
			(tallest = 0), (heights.length = 0); //reset vars
			items.each(function() {
				$(this).css("min-height", "0"); //reset min-height
			});
			normalizeHeights(); //run it again
		});
	}
}

$(document).ready(function() {
	carouselNormalization();

	$(".location-carousel").slick({
		dots: false,
		infinite: true,
		autoplay: false,
		autoplaySpeed: 2000,
		speed: 300,
		slidesToShow: 6,
		slidesToScroll: 2,
		arrows: true,
		responsive: [
			{
				breakpoint: 1440,
				settings: {
					arrows: true,
					slidesToShow: 5,
					slidesToScroll: 2,
					infinite: true
				}
			},
			{
				breakpoint: 1024,
				settings: {
					arrows: true,
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true
				}
			},
			{
				breakpoint: 600,
				settings: {
					arrows: true,
					slidesToShow: 2,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 480,
				settings: {
					arrows: true,
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	});

	$(".location-carousel").each(function() {
		let height = 0;
		$(this)
			.find(".location-carousel__location-title")
			.each(function() {
				if ($(this).height() > height) {
					height = $(this).height();
				}
			});

		$(this)
			.find(".location-carousel__location-title")
			.each(function() {
				$(this).height(height);
			});

		$(this)
			.find(".slick-next")
			.css("margin-top", height + "px");
		$(this)
			.find(".slick-prev")
			.css("margin-top", height + "px");
	});

	$(".stepped-carousel .previous").on("click", function(event) {
		steppedCarouselPrev(event);
	});

	$(".stepped-carousel .next").on("click", function(event) {
		steppedCarouselNext(event);
	});

	function steppedCarouselNext(e) {
		let carousel = $(e.currentTarget).closest(".stepped-carousel");

		carousel.fadeOut(function() {
			carousel
				.find("article:first-of-type")
				.appendTo(".stepped-carousel__articles");
			$(carousel).fadeIn();
		});
	}
	function steppedCarouselPrev(e) {
		let carousel = $(e.currentTarget).closest(".stepped-carousel");

		carousel.fadeOut(function() {
			carousel
				.find("article:last-of-type")
				.prependTo(".stepped-carousel__articles");
			$(carousel).fadeIn();
		});
	}
});