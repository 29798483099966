//Set page as active in nav
$(document).ready(function(){
    var path = window.location.pathname;
    $(".nav-item").each(function(index) {            
        $(this).find('a').each(function(i){
            if ($(this).attr("href") == path) {
                $(this).closest(".nav-item").addClass("active");
                return;
            }
        })
    });
    // $(".nav-item, .sub-nav-item").each(function(index) {            
    //     if ($(this).find('a').attr("href") == path) {
    //         $(this).addClass("active");
    //         return;
    //     }
    // });

    function toggleDropdown (e) {
        const _d = $(e.target).closest('.nav-item.dropdown'),
          _m = $('.dropdown-menu', _d);
        setTimeout(function(){
          const shouldOpen = e.type !== 'click' && _d.is(':hover');
          _m.toggleClass('show', shouldOpen);
          _d.toggleClass('show', shouldOpen);
          $('[data-toggle="dropdown"]', _d).attr('aria-expanded', shouldOpen);
        }, e.type === 'mouseleave' ? 300 : 0);
      }
      
      $('body')
        .on('mouseenter mouseleave','.nav-item.dropdown',toggleDropdown)
        .on('click', '.dropdown-menu a', toggleDropdown);
});
