function calloutToggle(open = false) {
    if (open) {
        if (!$(".callout").hasClass("hidden")) {
            return;
        }
    }

    $(".callout").toggleClass("hidden");
    let span = $(this).find("span");
    span.toggleClass("carousel-control-prev-icon");
    span.toggleClass("carousel-control-next-icon");
}

$(document).ready(function() {
    if(localStorage.getItem('newsletterClosed') != "true") {
        setTimeout(function() {
            if (screen.width > 767) {
                calloutToggle(true);
            }
        }, 5000);
    }

    $(".callout-open").on("click", function() {
        calloutToggle();
    });
    $(".callout-close").on("click", function() {
        calloutToggle();
        localStorage.setItem('newsletterClosed', "true")
    });
});
