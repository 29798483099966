$(document).ready(function() {
	$(".container-modification").on("change", "input, select", function() {
		getModificationOptions();
	});
	getModificationOptions();
});

function getModificationOptions() {
	let checkedInputs = [];
	let selectedOptions = [];

	// Loop through select elements with data-option attribute. Add values to selectedOptions array.
	$("[data-option]").each(function() {
		selectedOptions.push(this.value);
	});

	//Loop through checkbox elements with data-check attribute. Add values to array checkedInputs array.
	$("[data-check]:checked").each(function() {
		checkedInputs.push($(this).data("check"));
	});

	$(".customisation-list").html(checkedInputs.join(", "));
	$(".option-list").html(
		selectedOptions
			.filter(function(v) {
				return v !== "";
			})
			.join(", ")
	);

	// console.log(checkedInputs);
	// console.log(selectedOptions);
}
