<template>
    <div class="vue-archive">
		<div :class="parentClass">
			<div class="row align-items-center py-5 vue-archive-item" v-for="post, index in posts.data" :key="post.id">
				<div :class="{'col-md-7 order-last order-md-last': (index - ((page - 1) * limit)) % 2 == 0, 'col-md-7 order-last order-md-first': (index - ((page - 1) * limit)) % 2 != 0}">
					<p><strong>{{ post.title }}</strong></p>
					<p>{{ post.post.excerpt }}</p>
					<div :class="{'row justify-content-center justify-content-md-end': (index - ((page - 1) * limit)) % 2 == 0, 'row justify-content-center justify-content-md-start': (index - ((page - 1) * limit)) % 2 != 0}">
						<div :class="{'col-auto order-last order-md-last': (index - ((page - 1) * limit)) % 2 == 0, 'col-auto order-last order-md-first': (index - ((page - 1) * limit)) % 2 != 0}">
							<a class="btn btn-secondary" :href="interpolateAttribute(post.slug, '/')">Read More</a>
						</div>
						<component-social></component-social>
					</div>
				</div>
				<div class="col-md-5 order-first">
					<div class="embed-responsive embed-responsive-16by9">
						<a :href="post.featured_image" data-featherlight="image">
						<img class="embed-responsive-item" :src="post.featured_image" alt="post.title">
						</a>
					</div>
				</div>
			</div>
			<div class="row py-5 justify-content-center border-top border-secondary">
				<pagination class="col-auto vue-pagination" :data="posts" @pagination-change-page="getResults"></pagination>
			</div>
		</div>
	</div>

    
</template>

<script>
export default {
	data() {
		return {
			posts: {},
			page: 1,
			limit: 6,
			parentClass: 'loaded',
		};
	},
	props: ["tax"],

	methods: {
		dynamicAttribute: function(prefix, index, key) {
			return prefix + index + "-" + key;
		},
		interpolateAttribute: function(value, prefix = "", suffix = "") {
			return prefix + value + suffix;
		},
		getResults(page = 1) {
			this.parentClass = "loading";
			axios.get('/taxonomy/' + this.tax + '?page=' + page + '&count=6')
				.then(response => {
					this.posts = response.data;
					this.page = page;
					this.parentClass = "loaded";
				});
		}
	},
	computed: {
		
	},
	mounted() {
		this.getResults();
	},
	updated() {}
};
</script>
