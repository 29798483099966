/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require("./bootstrap-front");
require("./enquiry-form");

require("./container-modifications");

require("./hammer"); //Mobile swipe gestures used for featherlight gallery
require("./bookmarks"); //Bookmark Scrollbar
require("./callouts"); //Callouts Scrollbar
require("featherlight");
require("./featherlight-gallery");
require("./search.js");
require("./offcanvas.js");

require("./carousels");
require("./nav");
require("./banners");

require("./modernizer");

window.Vue = require("vue");

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 **/

Vue.component(
	"component-archive",
	require("./components/ComponentArchive.vue")
);
Vue.component(
	"component-social",
	require("./components/ComponentSocial.vue")
);
Vue.component('pagination', require('laravel-vue-pagination'));

$(document).ready(function() {
	if (document.querySelector("#app")) {
		const app = new Vue({
			el: "#app"
		}); 
	}
});

$(document).ready(function() {
	let maps = document.querySelectorAll(".map");

	if (maps.length) {
		const script = document.createElement("script");
		script.src =
			"https://maps.googleapis.com/maps/api/js?key=" + google_api_key; //set as window variable
		document.head.appendChild(script);

		script.onload = function() {
			maps.forEach(function(map) {
				let address = map.dataset.address;
				var geocoder = new google.maps.Geocoder();

				geocoder.geocode({ address: address }, function(
					results,
					status
				) {
					if (status === "OK") {
						let currentMap = new google.maps.Map(map, {
							zoom: 14,
							center: results[0].geometry.location
						});
						/*map.setCenter(results[0].geometry.location);*/
						let marker = new google.maps.Marker({
							map: currentMap,
							position: results[0].geometry.location
						});
					} else {
						console.log(
							"Geocode was not successful for the following reason: " +
								status
						);
					}
				});
			});
		};
	}

	$("table").addClass("table");

	$(".gallery a").featherlightGallery({
		gallery: {
			fadeIn: 300,
			fadeOut: 300
		},
		openSpeed: 300,
		closeSpeed: 300
	});
});

$(document).ready(function() {
	// Gets the video src from the data-src on each button
	var $videoSrc;
	$(".video-btn").click(function() {
		$videoSrc = $(this).data("src");
		console.log($videoSrc);
	});

	// when the modal is opened autoplay it
	$("#video-modal").on("shown.bs.modal", function(e) {
		// set the video src to autoplay and not to show related video. Youtube related video is like a box of chocolates... you never know what you're gonna get
		$("#video-modal-iframe").attr(
			"src",
			$videoSrc +
				"?rel=0&amp;showinfo=0&amp;modestbranding=1&amp;autoplay=1"
		);
	});

	// stop playing the youtube video when I close the modal
	$("#video-modal").on("hide.bs.modal", function(e) {
		// a poor man's stop video
		console.log();
		$("#video-modal-iframe").attr("src", $videoSrc);
	});

	// document ready


	$('img.featherlight-img').featherlight($(this).attr('src'));
});
